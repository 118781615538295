import { Component, OnInit, Inject, ViewChild, TemplateRef, Injectable } from '@angular/core';
import { IPagination, IWebApiService, IModel } from '@interfaces/base';
import { SearchListService } from '../../../../_helper/search-list.service';
import { SearchList } from '../../../../_models/base/search-list';
import { HttpClient } from '@angular/common/http';

import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialog,
} from '@angular/material/dialog';

@Component({
    selector: 'app-object-picker',
    templateUrl: './object-picker.component.html',
    styleUrls: ['./object-picker.component.scss'],
})
export class ObjectPickerComponent implements OnInit {
    @ViewChild('buttonsTemplate', { static: true }) buttonsTemplate: TemplateRef<any>;
    searchListModel: SearchList;
    searchListService: SearchListService;
    objectService: IWebApiService;
    selectedObjects: IModel[]
    constructor(
        private dialogRef: MatDialogRef<ObjectPickerComponent>,
        private readonly _httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {

        this.searchListModel = new SearchList()
        this.searchListModel.objectColumns = this.data.object.GetListViewObjectPicker();
        this.searchListService = new SearchListService(new this.data.service(_httpClient), this.searchListModel);
        this.selectedObjects = [];
    }

    ngOnInit(): void {
        this.searchListModel.objectColumns.push({
            prop: 'actions',
            name: 'Actions',
            sortable: false,
            cellTemplate: this.buttonsTemplate,
            minWidth: 100,
            width: 100
        });

        //TODO: Hack now to fix the layout problem
        var that = this;
        setTimeout(function () {
            that.setPage(that.searchListModel.objectListPaging)
        }, 100);
    }

    public onNoClick() {
        this.dialogRef.close();
    }

    onSelect(row: any): void {
        let selectedObject: IModel = new this.data.object.constructor(row);
        if (!this.selectedObjects.map(x => x.GetId()).includes(selectedObject.GetId())) {
            this.selectedObjects.push(selectedObject);
        }
    }

    removeItem(item: any): void {
        const index = this.selectedObjects.indexOf(item, 0);
        if (index > -1) {
            this.selectedObjects.splice(index, 1);
        }
    }

    onKeyupEvent(event: any): void {
        this.searchListService.onKeyupEvent(event);
    }

    onSort(event: any): void {
        this.searchListService.onSort(event);
    }

    setPage(pageInfo: IPagination) {
        this.searchListService.setPage(pageInfo);
    }
}
