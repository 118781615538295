import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { MatDialogModule } from '@angular/material/dialog';
import { SecurityInterceptor } from '@helper';
import { ErrorInterceptor } from '@helper';
import { PagesToggleService } from './@pages/services/toggler.service';
import { QuickviewService } from './@pages/components/quickview/quickview.service';
import { MessageService } from './@pages/components/message/message.service';
import { CommonModule } from '@angular/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedModule } from './@pages/components/shared.module';
import { MessageModule } from './@pages/components/message/message.module';
import { pgTabsModule } from './@pages/components/tabs/tabs.module';
import { pgListViewModule } from './@pages/components/list-view/list-view.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { pgCardModule } from './@pages/components/card/card.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RootLayoutComponent } from './@pages/layouts/root-layout/root-layout.component';
import { DefaultLayoutComponent } from './@pages/layouts/default-layout/default-layout.component';
import { DefaultBlankComponent } from './@pages/layouts/default-blank/default-blank.component';
import { ModalConfirmComponent } from './@pages/components/modals/modal-confirm/modal-confirm.component';
import { ObjectPickerComponent } from './@pages/components/modals/object-picker/object-picker.component';
import { SidebarComponent } from './@pages/components/sidebar/sidebar.component';
import { HeaderComponent } from './@pages/components/header/header.component';
import { MenuComponent } from './@pages/components/menu/menu.component';
import { QuickviewComponent } from './@pages/components/quickview/quickview.component';
import { SearchOverlayComponent } from './@pages/components/search-overlay/search-overlay.component';

@NgModule({
  declarations: [
    AppComponent,
    RootLayoutComponent,
    DefaultLayoutComponent,
    DefaultBlankComponent,
    ModalConfirmComponent,
    ObjectPickerComponent,
    SidebarComponent,
    HeaderComponent,
    MenuComponent,
    QuickviewComponent,
    SearchOverlayComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ,PerfectScrollbarModule,
SharedModule,
CommonModule,
MessageModule,
pgTabsModule,
FormsModule,
pgListViewModule,
MatDialogModule,
HttpClientModule,
HttpModule,
TabsModule.forRoot(),
BrowserAnimationsModule,
pgCardModule,
BsDropdownModule.forRoot(),
AccordionModule.forRoot(),
AlertModule.forRoot(),
ButtonsModule.forRoot(),
CollapseModule.forRoot(),
ModalModule.forRoot(),
ProgressbarModule.forRoot(),
TooltipModule.forRoot(),
TypeaheadModule.forRoot(),
NgxDatatableModule.forRoot({ messages: { emptyMessage: 'No data to display', totalMessage: 'total', selectedMessage: 'selected', }})],
  providers: [QuickviewService, MessageService, PagesToggleService, { provide: HTTP_INTERCEPTORS, useClass: SecurityInterceptor, multi: true }, { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
