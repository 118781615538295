import {DefaultBlankComponent, DefaultLayoutComponent } from './@pages/layouts';
import { AuthenticationGuard } from '@helper';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [ { path : '', pathMatch : 'full', redirectTo : '/automation/auth/login' } ,
 { path : 'login', pathMatch : 'full', redirectTo : '/automation/auth/login' } ,
{ path : 'automation', component : DefaultBlankComponent,  children: [  { path : 'auth', loadChildren : () => import('./auth/auth.module').then(m => m.AuthModule) }  ] } ,
{ path : 'automation', component : DefaultLayoutComponent,  children: [  { path : 'externalvendorpayment', canActivate : [AuthenticationGuard], loadChildren : () => import('./externalvendorpayment/externalvendorpayment.module').then(m => m.ExternalvendorpaymentModule) }  ] } ,
{ path : 'automation', component : DefaultLayoutComponent,  children: [  { path : 'hoursmanualadjustment', canActivate : [AuthenticationGuard], loadChildren : () => import('./hoursmanualadjustment/hoursmanualadjustment.module').then(m => m.HoursmanualadjustmentModule) }  ] } ,
{ path : 'automation', component : DefaultLayoutComponent,  children: [  { path : 'invoice', canActivate : [AuthenticationGuard], loadChildren : () => import('./invoice/invoice.module').then(m => m.InvoiceModule) }  ] } ,
{ path : 'automation', component : DefaultLayoutComponent,  children: [  { path : 'psatimecard', canActivate : [AuthenticationGuard], loadChildren : () => import('./psatimecard/psatimecard.module').then(m => m.PsatimecardModule) }  ] } ,
{ path : 'automation', component : DefaultLayoutComponent,  children: [  { path : 'vendor', canActivate : [AuthenticationGuard], loadChildren : () => import('./vendor/vendor.module').then(m => m.VendorModule) }  ] } ,
 { path : 'home', pathMatch : 'full', redirectTo : '/automation/externalvendorpayment/list', canActivate : [AuthenticationGuard] } ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
