import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ObjectPickerComponent } from '../@pages/components/modals/object-picker/object-picker.component';
import { HttpClient } from '@angular/common/http';
import { IModel } from '../_interfaces/base/imodel';

@Injectable({
  providedIn: 'root',
})
export class ObjectPickerService {
  
  constructor(
    private _matDialog: MatDialog
    ) {}

  pickObject<T, S>(
    BaseObject: { new(): T }, 
    ServiceObject: { new(_httpClient: HttpClient): S }, 
    acceptMultiple: boolean,
    callback: (object: any) => any): void {
    let baseObject:T = new BaseObject();

    let confirm = this._matDialog.open(ObjectPickerComponent, {
      data: {
        object: baseObject,
        service: ServiceObject,
        acceptMultiple: acceptMultiple
      }
    });

    
    confirm.afterClosed().subscribe((result):void => {
      callback(result);
    });
  }

}
