import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UserLogin } from '../_models/base/user-login';
import { User } from '../_models/user';

import { tap } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment'

@Injectable({ providedIn: 'root' })
export class AuthService {
    private apiEndpoint: string;
    public currentUser: Observable<User>;
    private currentUserSubject: BehaviorSubject<User>;

    constructor(private httpClient: HttpClient) {
        this.currentUserSubject = new BehaviorSubject(
            JSON.parse(localStorage.getItem('currentUser') || '{}')
        );
        this.currentUser = this.currentUserSubject.asObservable();
        this.apiEndpoint = `${environment.apiUrl}/v1/Login`;
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(user: User): Observable<User> {
        let login = new UserLogin();
        login.username = user.username;
        login.password = user.password;

        return this.httpClient.post(this.apiEndpoint, login).pipe(
            tap(async (user: User) => {
                if (user) {
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                    return user;
                } else return null;
            })
        );
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(new User());
    }
}
