export function toBoolean(value: boolean | string): boolean {
    if (value == '' || value == 'false') {
        return false;
    }
    if (value)
        return true;

    if (value == 'true')
        return true;

    return false;
}
