<div aria-hidden="false"
     class="modal fade slide-up disable-scroll in show"
     role="dialog"
     tabindex="-1"
     aria-modal="true"
     style="display: block;">
    <div class="modal-dialog modal-md">
        <div class="modal-content-wrapper">
            <div class="modal-content modal-object-picker">
                <div class="modal-header clearfix">
                    <div class="container-fluid p-0">
                        <div class="row no-gutters">
                            <div class="col text-left">
                                <h5 mat-dialog-title>
                                    Search for {{ data.object.GetClasName() }}
                                </h5>
                            </div>
                            <div class="col text-right">
                                <button class="btn btn-default btn-lg btn-larger m-r-20"
                                        (click)="onNoClick()">
                                    Cancel
                                </button>
                                <button *ngIf="data.acceptMultiple"
                                        class="btn btn-primary btn-lg btn-larger"
                                        mat-button
                                        [mat-dialog-close]="selectedObjects"
                                        cdkFocusInitial>
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col">
                            <div class="form-group form-group-default">
                                <label>Search</label>
                                <input type="text" class="form-control"
                                       (keyup)=onKeyupEvent($event) />
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="data.acceptMultiple">
                        <div class="col m-t-5 m-b-10">
                            <span class="m-r-10">Selected Items:</span>
                            <button *ngFor="let item of selectedObjects; let i = index" class="btn btn-chip active btn-xs m-r-5" (click)="removeItem(item)" type="button">
                                <i class="pg-icon">close</i> {{item.GetLabel()}}
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <ngx-datatable class="table table-hover"
                                           [rows]="searchListModel.objectList"
                                           [columns]="searchListModel.objectColumns"
                                           columnMode="force"
                                           [headerHeight]="43"
                                           [footerHeight]="50"
                                           [rowHeight]="65"
                                           [externalPaging]="true"
                                           [count]="searchListModel.objectListPaging.totalCount"
                                           [offset]="searchListModel.objectListPaging.offset"
                                           [limit]="searchListModel.objectListPaging.limit"
                                           (page)="setPage($event)"
                                           (sort)="onSort($event)">
                            </ngx-datatable>
                            <ng-template #buttonsTemplate let-row="row" let-value="value">
                                <div class="btn-group sm-m-t-10">
                                    <button *ngIf="!data.acceptMultiple" class="btn btn-primary btn-icon-left m-b-10 m-r-5" type="button" [mat-dialog-close]="row">
                                        <i class="pg-icon">tick</i>
                                        <span>Select</span>
                                    </button>
                                    <button *ngIf="data.acceptMultiple" class="btn btn-primary btn-icon-left m-b-10 m-r-5" type="button" (click)="onSelect(row)">
                                        <i class="pg-icon">tick</i>
                                        <span>Add</span>
                                    </button>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
