import { IUser } from '@interfaces'

export class User {
    id: number;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    token?: string;
    expires_in: number;

    constructor();
    constructor(obj: IUser);
    constructor(obj?: any) {
        this.id = this.id;
        this.username = this.username;
        this.password = this.password;
        this.firstName = this.firstName;
        this.lastName = this.lastName;
        this.token = this.token;
        this.expires_in = this.expires_in;
    }


}