import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'layout-default-blank',
    templateUrl: './default-blank.component.html',
    styleUrls: ['./default-blank.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DefaultBlankComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}
