import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RootLayoutComponent } from '../root-layout/root-layout.component';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DefaultLayoutComponent extends RootLayoutComponent
  implements OnInit {
  menuLinks = [
    {
      label: 'Payments',
      routerLink: 'externalvendorpayment/list',
      iconType: 'fi',
      iconName: 'dollar-sign',
      toggle: 'close',
    },
    {
      label: 'Hours Adjs',
      routerLink: 'hoursmanualadjustment/list',
      iconType: 'fi',
      iconName: 'clock',
      toggle: 'close',
    },
    {
      label: 'Invoices',
      routerLink: 'invoice/list',
      iconType: 'fi',
      iconName: 'send',
      toggle: 'close',
    },
    {
      label: 'Timecards',
      routerLink: 'psatimecard/list',
      iconType: 'fi',
      iconName: 'credit-card',
      toggle: 'close',
    },
    {
      label: 'Vendors',
      routerLink: 'vendor/list',
      iconType: 'fi',
      iconName: 'users',
      toggle: 'close',
    },
  ];
  ngOnInit() {
    this.changeLayout('menu-pin');
    //Will sidebar close on screens below 1024
    this.autoHideMenuPin();
  }
}
