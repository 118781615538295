import { PaginationCriteria, ObjectQueryCriteria, SearchList } from '@models/base';
import { IWebApiService, IResult, IPagination, IModel} from '@interfaces/base';

export class SearchListService {
  private currentSearch:string;
  constructor(
    private service: IWebApiService,
    private searchListModel: SearchList
  ) {

    this.searchListModel.objectListPaging = {
      offset: 0,
      limit: 5,
      totalCount: 0,
      totalPages: 0,
      search: '',
    };

    this.searchListModel.objectListSorting = {
      field: '',
      order: '',
    };
  }

  clearSearchValues(): void {
    this.searchListModel.objectListCached = [];
    this.searchListModel.objectListCacheControl = {};
    this.searchListModel.objectListPaging = {
      offset: 0,
      limit: 5,
      totalCount: 0,
      totalPages: 0,
      search: '',
    };
  }

  onSort(event: any): void {
    //Sorting event reset all cache and brings back to page 1
    this.clearSearchValues();
    const sort = event.sorts[0];
    this.searchListModel.objectListSorting.field = sort.prop;
    this.searchListModel.objectListSorting.order = sort.dir;
    this.searchListModel.objectListPaging.search = this.currentSearch;
    this.setPage(this.searchListModel.objectListPaging);
  }

  onKeyupEvent(event: any): void {
    if (!['Control', 'Shift', 'Alt', 'Meta'].includes(event.key)) {
      if (event.target != null) {
        if (event.target.value.length >= 3) {
          this.clearSearchValues();
          this.searchListModel.objectListPaging.search = event.target.value;
          this.setPage(this.searchListModel.objectListPaging);
        } else {
          if (this.searchListModel.objectListPaging.search) {
            if (this.searchListModel.objectListPaging.search.length > 0) {
              this.clearSearchValues();
              this.searchListModel.objectListPaging.search = '';
              this.setPage(this.searchListModel.objectListPaging);
            }
          }
        }
      }
    }
  }

  setPage(pageInfo: IPagination) {
    let objectQuery: ObjectQueryCriteria = new ObjectQueryCriteria();
    objectQuery.label = this.searchListModel.objectListPaging.search || '';
    this.currentSearch = objectQuery.label;
    objectQuery.pagination = new PaginationCriteria();
    objectQuery.pagination.pageNumber = pageInfo.offset;
    objectQuery.pagination.pageSize = pageInfo.limit;

    objectQuery.pagination.sorting = this.searchListModel.objectListSorting;

    const start = pageInfo.offset * pageInfo.limit;

    if (this.searchListModel.objectListCacheControl[pageInfo.offset]) {
      this.searchListModel.objectListPaging.offset = pageInfo.offset;
      this.searchListModel.objectList = this.searchListModel.objectListCached.slice(
        start,
        start + pageInfo.limit
      );
    } else {
      this.service.get(objectQuery).subscribe(
        (data: IResult<IModel[]>) => {
          if (data === null) {
            return;
          }
          this.searchListModel.objectListPaging = data.pagination;
          this.searchListModel.objectListPaging.search = this.currentSearch;
          if (!this.searchListModel.objectList) {
            this.searchListModel.objectListCached = new Array<IModel>(
              data.pagination.totalCount || 0
            );
          }
          const objectList = [...this.searchListModel.objectListCached];
          objectList.splice(start, pageInfo.limit, ...data.result);
          this.searchListModel.objectListCached = objectList;
          this.searchListModel.objectList = data.result;
          this.searchListModel.objectListCacheControl[pageInfo.offset] = true;
        },
        (error: any) => {
          //TODO: Manage errors
        }
      );
    }
  }
}
