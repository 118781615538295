import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObserversModule } from '@angular/cdk/observers';


import { QuickviewService } from './quickview/quickview.service';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { ContainerComponent } from './container/container.component';
import { PageContainerComponent } from './container/page-container.component';
import { RouterModule } from '@angular/router';
import { MenuAltComponent } from './menu/menu-alt.component';
import { MenuIconComponent } from './menu/menu-icon.component';

import { ListItemComponent } from './list-view/list-item/list-item.component';
import { ListViewContainerComponent } from './list-view/list-view-container/list-view-container.component';
import { pgRetinaDirective } from './retina/retina.directive';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [CommonModule, ObserversModule, TypeaheadModule.forRoot(), PerfectScrollbarModule, RouterModule],
    declarations: [
        ContainerComponent,
        PageContainerComponent,
        MenuAltComponent,
        MenuIconComponent,
        ListItemComponent,
        ListViewContainerComponent,
        pgRetinaDirective
    ],
    exports: [
        ContainerComponent,
        PageContainerComponent,
        MenuAltComponent,
        MenuIconComponent,
        ListItemComponent,
        ListViewContainerComponent,
        pgRetinaDirective
    ],
    providers: [
        QuickviewService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ]
})
export class SharedModule { }
