import 'reflect-metadata';
import { FormGroup } from '@angular/forms';

export class AutoMapper {
    public static MapFormGroup(destination: FormGroup, source: any): void {
      for (let [key, value] of Object.entries(source)) {
        const formControl = destination.get(key);
        if (formControl) {
          formControl.setValue(value);
        }
      }
    }
  
    public static MapObject(
      destination: any,
      source: any,
      prototype: any = null
    ): void {
      for (let [key, value] of Object.entries(source)) {
        if (destination.hasOwnProperty(key)) {
          if (prototype != null) {
            let customPropertType = Reflect.getOwnMetadata('data:datatype', prototype, key);
            if (customPropertType === undefined) {
              destination[key] = value;
            } else {
              //TODO: Needs to call ifself to map object object. Im waiting as I will find out how we will handle child objects
              //this.MapObject(destination[key] as typeof customPropertType, value as typeof customPropertType, (new customPropertType).prototype)
              destination[key] = new customPropertType();
              
            }
          } else destination[key] = value;
        }
      }
    }
  
    public static CustomDecorator(
      metadataKey: string,
      metadataValue: any
    ): PropertyDecorator {
      return function (ctorPrototype: any, propertyKey: string): void {
        // original functionality
        Reflect.defineMetadata(
          'data:' + metadataKey,
          metadataValue,
          ctorPrototype,
          propertyKey
        );
      };
    }
  }
  