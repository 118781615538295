<div aria-hidden="false" class="modal fade slide-up disable-scroll in show" role="dialog" tabindex="-1" aria-modal="true" style="display: block;">
  <div class="modal-dialog modal-md">
    <div class="modal-content-wrapper">
      <div class="modal-content">
        <div class="modal-header clearfix text-left">          
          <h5 mat-dialog-title>{{data.title}}</h5>
        </div>
        <div class="modal-body">
          <div class="row">
            <mat-dialog-content>{{data.message}}</mat-dialog-content>
          </div>
          <div class="row" mat-dialog-actions>
            <div class="text-center container-fluid">
              <button class="btn btn-default btn-lg btn-larger m-r-20" (click)="onNoClick()">No</button>
              <button class="btn btn-default btn-lg btn-larger" mat-button [mat-dialog-close]="data.return" cdkFocusInitial>Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  
</div>
