import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MessageService } from '../@pages/components/message/message.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthService,
    private router: Router,
    private notification: MessageService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      //retry(1),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // auto logout if 401 response returned from api
          if (this.authenticationService.currentUserValue != null) {
            this.authenticationService.logout();
            //location.reload(true);
            this.router.navigate(['/login']); //, { queryParams: { returnUrl: state.url } }
          }
        }
        //TODO: Create a WebAPI to log errors from frontend

        if (error.error instanceof ErrorEvent) {
          // client-side error
          return throwError(error.error);
        } else {
          // server-side error
          return throwError(error.error);
        }
      })
    );
  }
}
