<!-- WRAP LAYOUT IF BOXED -->
<div class="container" *ngIf="_boxed; else basicLayoutBlock">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</div>
<ng-template #basicLayoutBlock>
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</ng-template>
<!-- YOUR LAYOUT CONTENT GOES INSIDE HERE -->
<ng-template #contentTpl>
    <!-- PAGE SIDEBAR -->
    <pg-sidebar>

        <ng-template #sideBarHeader>
            <img src="assets/img/logo.png" pgRetina src2x="assets/img/logo_2x.png" alt="logo" class="brand"
                 width="78" height="22">
        </ng-template>
        <ng-template #menuItems>
            <pg-menu-items [Items]="menuLinks">
            </pg-menu-items>
        </ng-template>
    </pg-sidebar>
    <!-- PAGE CONTAINER -->
    <page-container>
        <pg-header [boxed]="_boxed">
            <!-- START MOBILE SIDEBAR TOGGLE -->
            <a href="javascript:void(0);" class="btn-icon-link toggle-sidebar d-lg-none" (click)="toggleMobileSidebar()">
                <i class="pg-icon">menu</i>
            </a>
            <!-- END MOBILE SIDEBAR TOGGLE -->
            <div class="d-flex align-items-center">

                <div class="brand" [class.d-none]="_layoutOption === 'email'" [class.d-xl-block]="_layoutOption === 'email'"
                     [class.d-lg-block]="_layoutOption === 'email'">
                    <img src="assets/img/logo.png" alt="logo" pgRetina src1x="assets/img/logo.png"
                         src2x="assets/img/logo_2x.png" width="78" height="22">
                </div>
                <a href="javascript:void(0)" class="search-link d-none m-r-20 d-lg-block d-xl-block" (click)="openSearch($event)">
                    <i class="pg-icon">search</i>Type anywhere to <span class="bold">search</span>
                </a>
            </div>
            <div class="d-flex align-items-center">
                <!-- START User Info-->
                <div class="pull-left p-r-10 fs-14 font-heading d-lg-inline-block d-none m-l-20">
                    <span class="semi-bold">{{_currentUser.firstName}}</span> <span class="text-color">{{_currentUser.lastName}}</span>
                </div>
                <div class="dropdown pull-right d-lg-block d-none" dropdown>
                    <button class="profile-dropdown-toggle" type="button" dropdownToggle id="profileDropdown"
                            aria-label="profile dropdown">
                        <span class="thumbnail-wrapper d32 circular inline">
                            <img src="assets/img/profiles/avatar.jpg" alt="" pgRetina src1x="assets/img/profiles/avatar.jpg"
                                 src2x="assets/img/avatar.jpg" width="32" height="32">
                        </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right profile-dropdown" *dropdownMenu role="menu">
                        <a href="#" class="dropdown-item"><span>Signed in as <br /><b>{{_currentUser.firstName}} {{_currentUser.lastName}}</b></span></a>
                        <div class="dropdown-divider"></div>
                        <a href="#" class="dropdown-item">Your Profile</a>
                        <div class="dropdown-divider"></div>
                        <a href="#" class="dropdown-item">Settings</a>
                        <a href="#" (click)="logout()" class="dropdown-item">Logout</a>
                    </div>
                </div>
                <!-- END User Info-->
                <a href="javascript:void(0)" class="header-icon btn-icon-link m-l-5 sm-no-margin d-inline-block btn-icon-link"
                   (click)="openQuickView($event)">
                    <i class="pg-icon">menu_add</i>
                </a>
            </div>
        </pg-header>
        <div class="page-content-wrapper {{_pageContainerClass}}">
            <!-- START PAGE CONTENT -->
            <div class="content {{_contentClass}}">
                <router-outlet></router-outlet>
            </div>
            <!-- END PAGE CONTENT -->
            <!-- START COPYRIGHT -->
            <ng-template [ngIf]="_footer">
                <div class=" container-fluid container-fixed-lg footer">
                    <div class="copyright sm-text-center">
                        <p class="small no-margin pull-left sm-pull-reset">
                            <span class="hint-text">Copyright &copy; 2020</span>&nbsp;<span class="font-montserrat">NETBIIS USA LLC</span>.
                            <span class="hint-text">All rights reserved.</span>
                        </p>

                        <p class="small no-margin pull-right sm-pull-reset">
                            Netbiis: <span class="hint-text">Technology Transforming Businesses</span>
                        </p>

                        <div class="clearfix">
                        </div>
                    </div>
                </div>
                <!-- END COPYRIGHT -->
            </ng-template>
        </div>
    </page-container>
    <!-- QUICKSEARCH -->
    <app-search-overlay></app-search-overlay>
    <!-- QUICKVIEW -->
    <app-quickview></app-quickview>
</ng-template>
