import { IPagination, ISorting, IModel } from '@interfaces/base';

export class SearchList {
  objectList: IModel[];
  objectListCached: IModel[];
  objectColumns: any = [];
  objectListCacheControl: any = {};
  objectListPaging: IPagination;
  objectListSorting: ISorting;
  constructor() {
    this.objectListCached = [];
    this.objectListCacheControl = {};
    this.objectListPaging = {
      offset: 0,
      limit: 5,
      totalCount: 0,
      totalPages: 0,
      search: '',
    };
    this.objectListSorting = {
      field: '',
      order: '',
    };
  }

  clearSearchValues(): void {
    this.objectListCached = [];
    this.objectListCacheControl = {};
    this.objectListPaging = {
      offset: 0,
      limit: 5,
      totalCount: 0,
      totalPages: 0,
      search: '',
    };
  }
}
